<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('bsri_report.subzone_consolidated_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
                <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="fiscal_year_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{$t('elearning_config.fiscal_year') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            id="fiscal_year_id"
                                            plain
                                            v-model="search.fiscal_year_id"
                                            :options="fiscalYearList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Mill Info" vid="mill_info_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="mill_info_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{$t('bsri_demandDIS.mill_name') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            id="mill_info_id"
                                            plain
                                            v-model="search.mill_info_id"
                                            :options="millInfoList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Subzone Office" vid="subzone_office_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="subzone_office_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{$t('bsri_demandDIS.subzone_office_name') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            id="subzone_office_id"
                                            plain
                                            v-model="search.subzone_office_id"
                                            :options="subzoneOfficeList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                                </b-col>
                               </b-row>
                        </b-form>
                    </ValidationObserver>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('bsri_report.subzone_consolidated_report_list') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button variant="primary" @click="pdfExport" class="mr-2">
                            {{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <!-- <export-excel
                            class="btn btn-success mr-2"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            :default-value="headerExcelDefault"
                            name="subsidy-wise-allocation-report.xls">
                            {{ $t('globalTrans.export_excel') }}
                          </export-excel> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="3">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                    <span style="text-decoration: underline;">{{ $t('bsri_report.subzone_consolidated_report') }}</span>
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                  <b-row>
                                    <b-col md="4"><strong>{{ $t('elearning_config.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                    <b-col md="4"><strong>{{ $t('bsri_demandDIS.mill_name') }}: </strong>{{ search.mill_info_id  ?  ($i18n.locale === 'en' ? searchResult.mill_name : searchResult.mill_name_bn) : $t('globalTrans.all') }}</b-col>
                                    <b-col md="4"><strong>{{ $t('bsri_demandDIS.subzone_office_name') }}: </strong>{{ search.subzone_office_id  ?  ($i18n.locale === 'en' ? searchResult.subzone_office_name : searchResult.subzone_office_name_bn) : $t('globalTrans.all') }}</b-col>
                                </b-row>
                                 </div>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                        <b-thead>
                                          <tr>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('bsri_report.cda_name') }}</b-th>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('bsri_report.center') }}</b-th>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('bsri_report.unit_no') }}</b-th>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('bsri_report.farmer_no') }}</b-th>
                                            <th class="text-center" :colspan="(cultivationMethod.length * 2) + 2">{{ $t('bsri_config.cultivation_method_name') }}</th>
                                          </tr>
                                           <tr>
                                              <th colspan="2" v-for="(item, index) in cultivationMethod" :key="index">
                                                 {{ $i18n.locale === 'bn' ?  item.cultivationMethod_name_bn :  item.cultivationMethod_name }}
                                                  ( {{ $t('bsri_demandDIS.acr_unit_first') }} {{ $n(item.per_acr_amount) }} {{ $t('bsri_demandDIS.acr_unit_second') }} )
                                              </th>
                                              <th style="vertical-align : middle;text-align:center;" colspan="2">{{ $t('bsri_demandDIS.total') }}</th>
                                          </tr>
                                          <tr>
                                            <template v-for="(item2, index2) in cultivationMethod">
                                              <th :key="index2+1">{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                              <th :key="index2+2"> {{ $t('bsri_demandDIS.amount_money') }}</th>
                                            </template>
                                            <th>{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                            <th>{{ $t('bsri_demandDIS.amount_money') }}</th>
                                          </tr>
                                        </b-thead>
                                       <b-tbody  v-for="(info, index3) in datas" :key="index3">
                                          <b-td class="text-center">{{  $n(index3 + 1) }}</b-td>
                                          <b-td class="text-center"></b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.center_name_bn : info.center_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.unit_no_bn : info.unit_no }}</b-td>
                                          <b-td class="text-center">{{ $n(info.total_farmers) }}</b-td>
                                          <template v-for="(item4, index4) in cultivationMethod">
                                            <td :key="index4+1">{{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].land_quantity) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                            <td :key="index4+2"> {{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].amount) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                            </template>
                                          <b-td class="text-center">{{ $n(info.total_quantity) }}</b-td>
                                           <b-td class="text-center">{{ $n(info.total_amount) }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subzoneConsolidatedReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
    },
    data () {
        return {
          search: {
            fiscal_year_id: 0,
            mill_info_id: 0,
            subzone_office_id: 0
          },
          searchResult: {
            fiscal_year: '',
            fiscal_year_bn: '',
            mill_name: '',
            mill_name_bn: '',
            subzone_office_name: '',
            subzone_office_name_bn: ''

          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false,
          subsidyList: [],
          upazilaList: [],
          unionList: [],
          cultivationMethod: [],
          subzoneOfficeList: []
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        // this.headerDataExcel()
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      millInfoList: function () {
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        return millInfoList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
      },
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
            headerVal[0] = this.headerExcelDefault.orgName
            headerVal[1] = this.headerExcelDefault.address
            headerVal[2] = this.$t('dae_reports.subsidy_wise_allocation_report')
            headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name
          } else {
            headerVal[0] = this.headerExcelDefault.orgNameBn
            headerVal[1] = this.headerExcelDefault.address_bn
            headerVal[2] = this.$t('dae_reports.subsidy_wise_allocation_report')
            headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year_bn + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name_bn
          }
          return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
            if (this.$i18n.locale === 'en') {
              return {
                'sl ': serial,
                'Subsidy Type': keyItem.sub_sidy_type_name,
                'Subsidy Name': keyItem.sub_sidy_name,
                'Crop Name': keyItem.crop_name,
                'District Name': keyItem.district_name,
                'Upazilla Name ': keyItem.upazilla_name,
                'Union Name': keyItem.union_name,
                'Agricultural material Type ': keyItem.mat_type_name,
                'Agriculture Material Name': keyItem.mat_name,
                'Agricultural material Quantity': keyItem.quantity
              }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'ভর্তুকির ধরণ': keyItem.sub_sidy_type_name_bn,
                'ভর্তুকির নাম': keyItem.sub_sidy_name_bn,
                'ফসলের নাম': keyItem.crop_name_bn,
                'জেলা ': keyItem.district_name_bn,
                'উপজেলা ': keyItem.upazilla_name_bn,
                'ইউনিয়ন ': keyItem.union_name_bn,
                'কৃষি উপকরণের ধরণ': keyItem.mat_type_name_bn,
                'পরিমাণ ': this.$n(keyItem.quantity)
              }
            }
        })
        return listContractor
      }
    },
    watch: {
      'search.mill_info_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.subzoneOfficeList = this.getSubZoneList(newVal)
        }
      }
    },
    methods: {
      getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
      },
      headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/8').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 8)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      pdfExport () {
          const reportTitle = this.$t('dae_reports.subsidy_wise_allocation_report')
          ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 8, reportTitle, this.datas, this, this.formData)
      },
      async searchData () {
      this.cultivationMethod = this.customCultivationInfo()
      this.loading = true
      this.showData = true
      const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, subzoneConsolidatedReport, params).then(response => {
          if (response.success) {
            if (response.data) {
              if (this.search.fiscal_year_id) {
                const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === this.search.fiscal_year_id)
                this.searchResult.fiscal_year = fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
                this.searchResult.fiscal_year_bn = fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
              }
              if (this.search.mill_info_id) {
                const millNameObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millItem => millItem.value === this.search.mill_info_id)
                this.searchResult.mill_name = millNameObj !== undefined ? millNameObj.text_en : ''
                this.searchResult.mill_name_bn = millNameObj !== undefined ? millNameObj.text_bn : ''
              }
              if (this.search.subzone_office_id) {
                const subzoneNameObj = this.$store.state.incentiveGrant.commonObj.subZoneList.find(subzoneItem => subzoneItem.value === this.search.subzone_office_id)
                this.searchResult.subzone_office_name = subzoneNameObj !== undefined ? subzoneNameObj.text_en : ''
                this.searchResult.subzone_office_name_bn = subzoneNameObj !== undefined ? subzoneNameObj.text_bn : ''
              }
              this.datas = this.getRelatinalData(response.data)
            }
          } else {
            this.datas = []
          }
        })
        this.loading = false
      },
      getRelatinalData (data) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList
        const unitList = this.$store.state.incentiveGrant.commonObj.unitList
        const listData = data.map(item => {
            const centerObject = centerList.find(center => center.value === item.center_id)
            const unitObject = unitList.find(unit => unit.value === item.unit_id)
            const ojbData = {
                center_name: centerObject !== undefined ? centerObject.text_en : '',
                center_name_bn: centerObject !== undefined ? centerObject.text_bn : '',
                unit_no: unitObject !== undefined ? unitObject.text_en : '',
                unit_no_bn: unitObject !== undefined ? unitObject.text_en : ''
            }
            return Object.assign({}, item, ojbData)
        })
        return listData
     },
      customCultivationInfo () {
        const cultivationPolicyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.fiscal_year_id === this.search.fiscal_year_id)
        const listData = cultivationPolicyList.map(item => {
          const cultivationMethodObject = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(methodItem => methodItem.value === item.method_cultivation_id)

          const cultivationMethodData = {
            cultivationMethod_name: cultivationMethodObject !== undefined ? cultivationMethodObject.text_en : ' ',
            cultivationMethod_name_bn: cultivationMethodObject !== undefined ? cultivationMethodObject.text_bn : ' '
          }
          return Object.assign({}, item, cultivationMethodData)
          })

        return listData
      }
    }
}
</script>
<style>
th {
 border: 1px solid black!important;
 font-size:12px!important;
}
.notbold{
  font-weight:normal;
  text-align: center;
}
</style>
